<template>
  <b-card
    v-if="data"
    no-body
  >
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title>
        {{ $t('Objectives_Progress') }}
      </b-card-title>
      <!--<b-dropdown
        no-caret
        right
        text="Last 7 Days"
        variant="transparent"
        class="chart-dropdown"
        toggle-class="p-50"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in data.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown>-->
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
      <b-row>
        <b-col
          sm="2"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ data.totalobj }}
          </h1>
          <small>   {{ $t('Goals') }}</small>
        </b-col>

        <!-- chart -->
        <b-col
          sm="10"
          class="d-flex justify-content-center"
        >

          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptions"
            :series="data.supportTrackerRadialBar.series"
          />
        </b-col>
        <!--/ chart -->
      </b-row>

      <!-- chart info -->
      <div class="d-flex justify-content-between " style="margin-top:60px">
        <div class="text-center">
          <b-card-text class="mb-50">
             {{ $t('Completed') }}
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ data.doneObj }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
             {{ $t('Open_Objectives') }}
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ data.inprogress }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            {{ $t('Delete_objectives') }}
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ data.deleted }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import axios from "axios";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  data() {
    return {
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Completed Objectives'],
        },
      },
      data : {
        title: 'Objectives Progress',
        lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
        totalobj: 0,
        doneObj: 0,
        inprogress: 0, 
        deleted: 0,
        supportTrackerRadialBar: {
          series: [100],      
        },
      },
    }
  },
  created() {
    axios
    .get(
      "https://backendapinodejs.timecheckit.com/api/objectifs?user=" + JSON.parse(localStorage.getItem("userData"))._id
    )
    .then((response) => {
      console.log("response",response)
      this.data.totalobj = response.data.count
      this.data.doneObj = response.data.data.filter(obj => obj.isDone === true && !obj.isDeleted).length;
      this.data.inprogress = response.data.data.filter(obj => !obj.isDeleted && !obj.isDone).length;
      this.data.deleted = response.data.data.filter(obj => obj.isDeleted === true).length;
      this.data.supportTrackerRadialBar.series = [Math.floor((this.data.doneObj / this.data.totalobj) * 100)]
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
  }
}
</script>
