import axios from "axios"
import store from "..";


export default {

  //   fetchTasks() {
  //     console.log("FETCH WORKING",store.state.todoModule);
  //     axios
  //       .get('https://backendapinodejs.timecheckit.com/api/todos/').then((res)=>{
  //         store.state.todoModule.todos=res.data.data
  //         store.state.todos=res.data.data
  //          console.log("FETCH WORKING!",store.state.todoModule.todos);
  //          localStorage.setItem("todos",JSON.stringify(store.state.todos))
  //        })

  fetchTasks({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('https://backendapinodejs.timecheckit.com/api/todos?user=' + JSON.parse(localStorage.getItem("userData"))._id).then((res) => {
          // var todos = [];
          // for (let item of res.data.data) {
          //   var element =
          //     element = item
          //   element["startDate"] = item.date.startDate
          //   element["endDate"] = item.date.endDate
          //   todos.push(element)
          // }
          commit("SET_TODO_COUNT", [res.data]);
          commit("SET_INITIAL_DATA", res.data);
          console.log("FETCH WORKING!", store.state.todoModule.todos);
          commit("SET_TODO_DATA", res.data)
          resolve(res)
          //localStorage.setItem("todos", JSON.stringify(store.state.todos))
        })
        .catch(error => reject(error));
    })
  },

  addTask(ctx, taskData) {
    console.log("add todo", ctx, taskData)
    if (!taskData.assignee) taskData.assignee = []
    else {
      var arrayTemp = []
      taskData.assignee.forEach(element => {
        arrayTemp.push(
          element.id
        )
      });
    }
    taskData.assignee = arrayTemp
    taskData.user = JSON.parse(localStorage.getItem("userData"))._id
    axios
      .post('https://backendapinodejs.timecheckit.com/api/todos', taskData)
      .then((res) => {
        ctx.dispatch('fetchTasks');
        // window.location.reload()
        console.log("ADDED", res);
      })
      .catch(error => console.log("erreur", error))
  },

  updateTask(ctx, taskData) {
    console.log("taskData-update 1", taskData);
    // var idToUpdate = taskData._id;
    // delete taskData._id;
    // if (taskData.assignee) {
    //   var tempAssignee = []
    //   taskData.assignee.forEach(element => {
    //     tempAssignee.push(element._id)
    //   });
    // }
    // if (taskData.user) {
    //   taskData.user = taskData.user._id
    // }
    // if (taskData.date) {
    //   taskData.startDate = taskData.date.startDate
    //   taskData.endDate = taskData.date.endDate
    // }
    // if (taskData.tags) {
    //   var tempTags = []
    //   taskData.tags.forEach(element => {
    //     tempTags.push(element._id)
    //   });
    // }
    // taskData.tags = tempTags
    // taskData.assignee = tempAssignee

    // console.log("taskData-update 2", taskData);
    // axios
    //   .put('https://backendapinodejs.timecheckit.com/api/todos/' + idToUpdate, taskData)
    //   .then((res) => {
    //     console.log("UPDATED");
    //   })
    //   .catch(error => console.log(error))
  },
  removeTask(ctx, itemid) {
    axios
      .delete('https://backendapinodejs.timecheckit.com/api/todos/' + itemid)
      .then((res) => {
        ctx.dispatch('fetchTasks');
        console.log("DELETED");
      })
      .catch(error => console.log('error delete', error))
  }
}
