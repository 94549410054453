export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/todo/tag/:tag',
    name: 'Todo-tag',
    component: () => import('@/views/todo/Todo.vue'),
  },

  // {
  //   path: '/Tags',
  //   name: 'tags',
  //   component: () => import('@/views/Tags/Tags'),
  //   // meta: {
  //   //   contentRenderer: 'sidebar-left',
  //   //   contentClass: 'todo-application',
  //   //   navActiveLink: 'apps-todo',
  //   // },
  // },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('@/views/chat/chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  // *===============================================---*
  // *--------- PROJECTS  ---------------------------------------*

  {
    path: '/apps/projects',
    name: 'apps-projects',
    component: () => import('@/views/project/Projects.vue'),
  },

  {
    path: "/apps/project/:id",
    name: "project-details",
    component: () => import('@/views/project/Project.vue'),
  },
  // *===============================================---*
  // *--------- OBJECTIVES & IT'S FILTERS N TAGS ---------------------------------------*

  // *===============================================---*
  {
    path: '/apps/objectifs',
    name: 'apps-objectifs',
    component: () => import('@/views/objectifs/Objectifs.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/objectifs/:filter',
    name: 'apps-obj-filter',
    component: () => import('@/views/objectifs/Objectifs.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-objectifs',
    },
    beforeEnter(to, _, next) {
      if (['done', 'progress', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/objectifs/tag/:tag',
    name: 'apps-obj-tag',
    component: () => import('@/views/objectifs/Objectifs.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-obj',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/project/add-project',
    name: 'add-project',
    component: () => import('@/views/project/AddProject.vue'),
    meta: {
      pageTitle: 'Project',
      breadcrumb: [
        {
          text: 'add project',
          active: true,
        },
      ],
    },
  },
  {
    path: '/project/edit-project',
    name: 'edit-project',
    component: () => import('@/views/project/EditProject.vue'),
    meta: {
      pageTitle: 'Project',
      breadcrumb: [
        {
          text: 'Edit project',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- MEETINGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/meetings',
    name: 'apps-meetings',
    component: () => import('@/views/meetings/mymeeting/Mymeetings.vue'),
  },
  {
    path: '/apps/requests',
    name: 'apps-requests',
    component: () => import('@/views/meetings/myRequests/Requests.vue'),
  },
  /*{
    path: '/apps/meetings/preview',
    name: 'apps-meetings-preview',
    component: () => import('@/views/meetings/MeetingPreview.vue'),
  },*/
  {
    path: '/apps/meetings/preview',
    name: 'apps-meetings-preview',
    component: () => import('@/views/meetings/MeetingPreview.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
      // General 
  {
    path: '/apps/meetings/:id',
    name: 'apps-meetings-general',
    component: () => import('@/views/meetings/Meetings.vue'),
    meta: {
      pageTitle: 'Meetings',
      breadcrumb: [
        {
          text: 'Meeting General Settings',
          active: true,
        },
      ],
    },
  },
  // Invites
  {
    path: '/apps/meetings/add-meeting',
    name: 'apps-meetings-add',
    component: () => import('@/views/meetings/AddMeeting.vue'),
    meta: {
      pageTitle: 'Add Meeting',
      breadcrumb: [
        {
          text: 'Add Meeting',
          active: true,
        },
      ],
    },
  },
   // Advanced Settings
   {
    path: '/apps/meetings/advanced/:id',
    name: 'apps-meetings-advanced',
    component: () => import('@/views/meetings/advanced/Meeting-advanced.vue'),
    meta: {
      pageTitle: 'Meetings',
      breadcrumb: [
        {
          text: 'Meeting Advanced Settings',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- Tags ---------------------------------------*
  // *===============================================---*

  {
    path: '/apps/tags',
    name: 'apps-tags',
    component: () => import('@/views/Tags/Tag.vue'),
  },
]

