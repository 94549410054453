<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="
                $emit('update:is-task-handler-sidebar-active', true);
                $emit('close-left-sidebar');
              "
            >
           {{ $t('add_tasks') }}
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="(filter, index) in taskFilters"
                :key="index"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('change-display')"
              >
                <feather-icon :icon="filter.icon" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1">
                  {{
                    $t(filter.title)
                 }} 
            </span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tags -->
            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">Tags</h6>
              <feather-icon icon="PlusIcon" />
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                class="cursor-pointer"
                @click="$emit('tag-filter', {title:'all'})"
              >
                <span class="bullet bullet-sm mr-1 bullet-all" />
                <span>   {{ $t('tous') }}</span>
              </b-list-group-item>
              <b-list-group-item
                class="cursor-pointer"
                v-for="(tag, index) in tagsOptions"
                :key="index"
                @click="$emit('tag-filter', tag)"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :style="getBackgroundColor(tag.color)"
                />
                <span>{{ tag.title }}</span>
              </b-list-group-item>
            </b-list-group>
            <!-- :to="tag.route"
                :active="isDynamicRouteActive(tag.route)" -->
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BButton, BListGroup, BListGroupItem } from "bootstrap-vue";
import { isDynamicRouteActive } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";
import axios from "axios";
export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  props: {
    taskTags: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    const taskFilters = [
      { title: "my_tasks", icon: "MailIcon", route: { name: "apps-todo" } },
      {
        title: "completed",
        icon: "CheckIcon",
        route: { name: "apps-todo-filter", params: { filter: "completed" } },
      },
      {
        title: "deleted",
        icon: "TrashIcon",
        route: { name: "apps-todo-filter", params: { filter: "deleted" } },
      },
    ];

    return {
      perfectScrollbarSettings,
      taskFilters,
      isDynamicRouteActive,
    };
  },
  data() {
    return {
      tagsOptions: [],
    };
  },
  created() {
    this.getTags();
  },
  methods: {
    getTags() {
      axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/tags?user=" +
            JSON.parse(localStorage.getItem("userData"))._id
        )
        .then((response) => {
          console.log("tags", response.data.data);
          response.data.data.forEach((element) => {
            this.tagsOptions.push({
              title: element.name,
              color: element.color,
              value: element._id,
              route: { name: "apps-todo-tag", params: { tag: element.name } },
            });
          });
          console.log("this.taskTags", this.taskTags);
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    getBackgroundColor(color) {
      if (color) return "background-color:" + color;
    },
  },
};
</script>

<style>
.bullet-all {
  background: #000;
}
</style>
